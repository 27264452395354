'use strict';
// external js: isotope.pkgd.js

// init Isotope
var filters = {};
var qsRegex;

var $grid = $('.grid').isotope({
    itemSelector: '.element-item',
    filter: function() {
        var selector;
        if(!jQuery.isEmptyObject(filters)) {
            var isoFilters = [];
            for ( var prop in filters ) {
                isoFilters.push( filters[ prop ] );
            }
            selector = isoFilters.join('');
        }

        var $this = $(this);
        var searchResult = qsRegex ? $this.text().match( qsRegex ) : true;
        var buttonResult = selector ? $this.is( selector ) : true;
        return searchResult && buttonResult;
    }
});

$grid.on( 'arrangeComplete', onArrange );


// // bind filter button click
// $('#filters').on( 'click', 'button', function() {
//     var filterValue = $( this ).attr('data-filter');
//     // use filterFn if matches value
//     filterValue = filterFns[ filterValue ] || filterValue;
//     $grid.isotope({ filter: filterValue });
// });

// filter buttons
$('.filter a').click(function(e){
    e.preventDefault();
    var $this = $(this);
    // don't proceed if already selected
    if ( $this.hasClass('selected') ) {
        return;
    }

    var $optionSet = $this.parents('.option-set');
    // change selected class
    $optionSet.find('.selected').removeClass('selected');
    $this.addClass('selected');

    var group = $optionSet.attr('data-filter-group');
    filters[ group ] = $this.attr('data-filter-value');

    if(group === 'state') {
        $('#filter-state').html($this.html() + ' <i class="fa fa-chevron-down" aria-hidden="true"></i>');
    }
    if(group === 'expertise') {
        $('#filter-expertise').html($this.html() + ' <i class="fa fa-chevron-down" aria-hidden="true"></i>');
    }


    var isoFilters = [];
    for ( var prop in filters ) {
        isoFilters.push( filters[ prop ] );
    }
    var selector = isoFilters.join('');
    $grid.isotope();

    jQuery('#result-selected').html('Selected: '+selector);

    return false;
});

function onArrange() {
    var show_executive = 0;
    $('#team-executive > .element-item').each(function(item) {
        if(jQuery(this).is(":visible")) {
            show_executive = 1;
        }
    });
    var show_360 = 0;
    $('#team-s360 > .element-item').each(function(item) {
        if(jQuery(this).is(":visible")) {
            show_360 = 1;
        }
    });

    if(show_executive === 1) {
        $('#header-team-executive').show();
    }
    else {
        $('#header-team-executive').hide();
    }

    if(show_360 === 1) {
        $('#header-team-s360').show();
    }
    else {
        $('#header-team-s360').hide();
    }

}

function debounce( fn, threshold ) {
    var timeout;
    return function debounced() {
        if ( timeout ) {
            clearTimeout( timeout );
        }
        function delayed() {
            fn();
            timeout = null;
        }
        timeout = setTimeout( delayed, threshold || 100 );
    };
}

var $quicksearch = $('.quicksearch').keyup( debounce( function() {
    qsRegex = new RegExp( $quicksearch.val(), 'gi' );
    $grid.isotope();
}, 200 ) );

$('#clear-all-filter').click(function(e){
    e.preventDefault();
    $('#filter-state').val(0);
    $('#filter-expertise').val(0);
    $('#filter-state-any').click();
    $('#filter-expertise-any').click();
});

$('#filter-state').click(function(e){
    e.preventDefault();
});

$('#filter-expertise').click(function(e){
    e.preventDefault();
});

$(".team-image.lazy").Lazy({
    // your configuration goes here
    scrollDirection: 'vertical',
    effect: 'fadeIn',
    effectTime : 500,
    visibleOnly: true,
    afterLoad: function(element) {
        $(element).removeClass('loading-img');
    }
});
